const artists = [{
  id: 'mbolt',
  sceneName: 'Małgorzata Bolt, „Water Memory”',
  name: 'artists.mbolt.name',
  fromShort: 'artists.mbolt.fromShort',
  fromLong: 'artists.mbolt.fromLong',
  bio: 'artists.mbolt.bio',
  artwork: 'artists.mbolt.artwork',
  description: 'artists.mbolt.description',
  mapProps: { cx: 3810.7193, cy: 2700.65172 },
}, {
  id: 'mgniady',
  sceneName: 'Maciej Gniady - Słoń',
  name: 'artists.mgniady.name',
  fromShort: 'artists.mgniady.fromShort',
  fromLong: 'artists.mgniady.fromLong',
  bio: 'artists.mgniady.bio',
  artwork: 'artists.mgniady.artwork',
  description: 'artists.mgniady.description',
  mapProps: { cx: 1980.2993, cy: 832.65172 },
}, {
  id: 'kgrzywacz',
  sceneName: 'Krystian Grzywacz - Martwa Natura ',
  name: 'artists.kgrzywacz.name',
  fromShort: 'artists.kgrzywacz.fromShort',
  fromLong: 'artists.kgrzywacz.fromLong',
  bio: 'artists.kgrzywacz.bio',
  artwork: 'artists.kgrzywacz.artwork',
  description: 'artists.kgrzywacz.description',
  mapProps: { cx: 647.2793, cy: 689.94172 }
}, {
  id: 'mmikolajczykabuczynska',
  sceneName: 'Marta Mikołajczyk i Agnieszka Buczyńska - Portret',
  name: 'artists.mmikolajczykabuczynska.name',
  fromShort: 'artists.mmikolajczykabuczynska.fromShort',
  fromLong: 'artists.mmikolajczykabuczynska.fromLong',
  bio: 'artists.mmikolajczykabuczynska.bio',
  artwork: 'artists.mmikolajczykabuczynska.artwork',
  description: 'artists.mmikolajczykabuczynska.description',
  mapProps: { cx: 1624.0593, cy: 1087.67172 },
}, {
  id: 'hmuszynski',
  sceneName: 'Horacy Muszyński - A ja Ciebie kocham, a ja Ciebie lubię',
  name: 'artists.hmuszynski.name',
  fromShort: 'artists.hmuszynski.fromShort',
  fromLong: 'artists.hmuszynski.fromLong',
  bio: 'artists.hmuszynski.bio',
  artwork: 'artists.hmuszynski.artwork',
  description: 'artists.hmuszynski.description',
  mapProps: { cx: 1184.2293, cy: 385.82172 },
}, {
  id: 'kmutke',
  sceneName: 'Kacper Mutke - Obserwatorium dźwięku',
  name: 'artists.kmutke.name',
  fromShort: 'artists.kmutke.fromShort',
  fromLong: 'artists.kmutke.fromLong',
  bio: 'artists.kmutke.bio',
  artwork: 'artists.kmutke.artwork',
  description: 'artists.kmutke.description',
  contributors: 'artists.kmutke.contributors',
  mapProps: { cx: 2632.7993, cy: 1201.39172 },
}, {
  id: 'swildstein',
  sceneName: 'Szymon Wildstein - Klasztor głupiego myślenia',
  name: 'artists.swildstein.name',
  fromShort: 'artists.swildstein.fromShort',
  fromLong: 'artists.swildstein.fromLong',
  bio: 'artists.swildstein.bio',
  artwork: 'artists.swildstein.artwork',
  description: 'artists.swildstein.description',
  mapProps: { cx: 1201.5793, cy: 843.48172 },
}, {
  id: 'awitczak',
  sceneName: 'Agata Witczak - Pozostałość',
  name: 'artists.awitczak.name',
  fromShort: 'artists.awitczak.fromShort',
  fromLong: 'artists.awitczak.fromLong',
  bio: 'artists.awitczak.bio',
  artwork: 'artists.awitczak.artwork',
  description: 'artists.awitczak.description',
  mapProps: { cx: 1570.1893, cy: 597.13172 },
}];

export default artists;
