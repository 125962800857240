import { usePresentationOpenContext } from '../context/presentationOpen';
import {useEffect} from "react";

function ArtistsView() {
  const { setPresentationOpen } = usePresentationOpenContext();
  useEffect(() => {
    setPresentationOpen(true);
    return () => setPresentationOpen(false)
  }, [setPresentationOpen]);
  return null;
}

export default ArtistsView;
