import pl from './locales/pl';

export const locales = {
  pl,
  'pl-PL': pl,
};
const loaders = {
  'en-GB': () => import(/* webpackChunkName: "localePl" */ './locales/en'),
};

export function getLocale(lang) {
  const langShort = lang.substr(0, 2);
  const loadedMessages = locales[lang];
  let promise;

  if (!loadedMessages) {
    if (loaders[lang]) {
      promise = loaders[lang]();
    } else if (loaders[langShort]) {
      promise = loaders[langShort]();
    } else {
      return Promise.reject(Error(`No such language: ${lang}`));
    }
    return promise.then((locale) => {
      const messages = locale.default;
      if (!locales[lang]) {
        locales[lang] = messages;
      }
      return messages;
    });
  }
  return Promise.resolve(loadedMessages);
}

export function getMessageById(id) {
  return { id, defaultMessage: pl[id] || id };
}

export function translate(intl, id, val, defaultMessage) {
  let values = val;
  let messageId = id;
  if (typeof id === 'object') {
    values = id.val;
    messageId = id.id;
  }
  return intl.formatMessage(
    defaultMessage
      ? { id, defaultMessage }
      : getMessageById(messageId),
    values,
  );
}

export function getTranslationAgent(intl) {
  return (id, val, defaultMessage) => translate(intl, id, val, defaultMessage);
}

export default locales;
