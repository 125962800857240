const headersStyle = {
  fontFamily: [
    'utopia-std',
    'pt-serif-pro',
  ].join(','),
  fontWeight: 700,
};

const main = {
  spacing: factor => (factor === 'auto' ? factor : `${0.5 * factor}rem`),
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'aktiv-grotesk',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    fontStyle: 'normal',
    h1: headersStyle,
    h2: headersStyle,
    h3: headersStyle,
    h4: headersStyle,
    h5: headersStyle,
    h6: headersStyle,
    body1: {
      fontWeight: 'bold'
    },
  },
  palette: {
    cancelled: { main: ' rgba(0,0,0,0.54)' },
    error: { main: '#e94a35' },
    expired: { main: '#5a6163' },
    hover: { main: '#c0dccf' },
    incoming: { main: '#3178a5' },
    info: { main: '#ffab40' },
    new: { main: '#54b3e2' },
    // primary: { main: '#e35645', contrastText: '#ffffff', },
    // secondary: { main: '#f59e19', contrastText: '#ffffff' },
    primary: { main: '#2d3f57', contrastText: '#f59e19', },
    secondary: { main: '#e35645', contrastText: '#f59e19' },
    success: { main: '#6aab8d' },
    warning: { main: '#e57373' },
    opacity: { main: 'rgba(0, 0, 0, 0.25)' },
    background: {
      main: 'rgba(244, 184, 194, 0.85)',
      light: 'rgba(244, 184, 194, 0.66)',
      dark: 'rgba(244, 184, 194, 1)',
    },
  },
  // custom properties
};

export default main;
