import React, { useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Grid, Paper, Slide, useTheme, useMediaQuery, makeStyles, IconButton, Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Menu from './Menu';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.opacity.main,
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 48px)',
      justifyContent: 'center',
    },
  },
  content: {
    position: 'relative',
    padding: theme.spacing(2),
    flex: 1,
    maxWidth: 600,
    backgroundColor: theme.palette.background.main,
    height: '100%',
    overflow: 'auto',
  },
  title: {
    minWidth: 0,
    flex: 1,
    marginBottom: theme.spacing(2),
  },
  menuButton: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  mobileMenu: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  mobileMenuBackdrop: {
    backgroundColor: theme.palette.opacity.main,
    flex: 1,
    height: '100%',
  },
  toolbar: {
    minWidth: 0,
    zIndex: 1100,
  },
  actions: {
    display: 'flex',
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
}));

function Layout({ title, actions, children }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleMenu = useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);
  const stopPropagation = useCallback(e => e.stopPropagation(), []);
  const handleBackdropClick = useCallback(() => {
    if (!mobile) {
      history.push({
        pathname: '/exhibition',
        hash: history.location.hash,
        state: { backUrl:  history.location.pathname },
      });
    }
  }, [history, mobile]);
  return (
    <Grid container direction="row" className={classes.root}>
      {mobile ? (
        <Grid container item direction="row" alignItems="center" className={classes.toolbar}>
          <IconButton size="small" color="secondary" aria-label="back" onClick={handleToggleMenu} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          {menuOpen && (
            <Grid container direction="row" className={classes.mobileMenu}>
              <Grid item>
                <Menu toggleMenu={handleToggleMenu} />
              </Grid>
              <Grid item className={classes.mobileMenuBackdrop} onClick={handleToggleMenu} />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item>
          <Menu />
        </Grid>
      )}
      <Grid item className={classes.wrapper} onClick={handleBackdropClick}>
        <Slide in direction="up">
          <Paper elevation={2} className={classes.content} onClick={stopPropagation}>
            <Grid container direction="row" justify="space-between">
              {title ? <Typography color="secondary" variant="h4" className={classes.title}>{title}</Typography> : null}
              <Grid item className={classes.actions}>
                {actions}
                <Link
                  to={{ pathname: '/exhibition', hash: location.hash, state: { backUrl: location.pathname } }}
                  className={classes.link}
                >
                  <IconButton color="secondary" aria-label="close-menu">
                    <CloseIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
            {children}
          </Paper>
        </Slide>
      </Grid>
    </Grid>
  )
}

export default Layout;
