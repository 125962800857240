import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useLocalization } from '../context/localization';
import Layout from '../components/Layout';
import Map from '../components/Map';

const useStyles = makeStyles(theme => ({
  mapWrapper: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

function MapView() {
  const classes = useStyles();
  const { translate } = useLocalization();
  return (
    <Layout title={translate('map.title')}>
      <Grid item className={classes.mapWrapper}>
        <Map />
      </Grid>
    </Layout>
  )
}

export default MapView;
