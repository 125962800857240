import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

function MenuButton() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const handleExitExhibition = useCallback(() => {
    window.player?.setConfig({ rotateScene: true });
    history.push({ pathname: history.location.state?.backUrl || '/', hash: history.location.hash });
  }, [history]);
  return (
    <IconButton size={mobile ? 'small' : 'medium'} color="secondary" aria-label="back" className={classes.root} onClick={handleExitExhibition}>
      {mobile ? <ArrowBack /> : <MenuIcon />}
    </IconButton>
  )
}

export default MenuButton;
