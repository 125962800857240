import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { Paper, Grid, Typography, IconButton, MenuItem, makeStyles } from '@material-ui/core';
import { useLocalization } from '../context/localization';
import availableLanguages from '../services/localization/available';
import { ReactComponent as Logo } from '../static/wroArtCenterLogo.svg';
import { ReactComponent as ExhibitionLogo } from '../static/exhibitionLogo.svg';
import { ReactComponent as BackgroundImage } from '../static/circleGroup1.svg';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.dark,
    },
  },
  logo: {
    color: theme.palette.secondary.main,
    height: 50,
    marginRight: 20,
  },
  exhibitionLogo: {
    width: 100,
    color: theme.palette.secondary.contrastText,
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
  logoLink: {
    textDecoration: 'none',
    color: 'inherit',
    outline: 'none',
  },
  menuItem: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  activeMenuItem: {
    color: theme.palette.secondary.main,
  },
  locales: {
    alignSelf: 'flex-end',
  },
  backgroundImage: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: 160,
    color: theme.palette.secondary.main,
  },
}));

function Menu({ toggleMenu }) {
  const classes = useStyles();
  const { locale, translate, changeLocale } = useLocalization();
  const location = useLocation();
  const menuItems = useMemo(() => [{
    id: 'about',
    link: '/',
    label: translate('about.title'),
  }, {
    id: 'curatorialText',
    link: '/curatorialText',
    label: translate('curatorialText.title'),
  }, {
    id: 'artists',
    link: '/artists',
    label: translate('artists.title'),
  }, {
    id: 'map',
    link: '/map',
    label: translate('map.title'),
  }, {
    id: 'prizes',
    link: '/prizes',
    label: translate('prizes.title'),
  }, {
    id: 'conference',
    link: '/conference',
    label: translate('conference.title'),
  }, {
    id: 'organizers',
    link: '/organizers',
    label: translate('organizers.title'),
  }], [translate]);
  const handleChangeLanguage = useCallback((lngKey) => {
    changeLocale(lngKey);
  }, [changeLocale]);
  return (
    <Paper elevation={3} square className={classes.root}>
      <BackgroundImage className={classes.backgroundImage} />
      <Grid container direction="column" justify="space-between">
        <Grid item>
          <Link
            to={{ pathname: '/', hash: location.hash }}
            className={classes.logoLink}
          >
            <Grid container direction="row">
              <Logo className={classes.logo} />
              <ExhibitionLogo className={classes.exhibitionLogo} />
            </Grid>
          </Link>
          <Grid item container direction="column">
            {menuItems.map(item => {
              const isActive = item.link === '/' ? location.pathname === item.link : location.pathname.includes(item.link);
              return (
                <Grid item key={item.id}>
                  {item?.onClick ? (
                    <MenuItem
                      classes={{ root: clsx({
                          [classes.menuItem]: true,
                          [classes.activeMenuItem]: isActive,
                        })}}
                      onClick={item.onClick}
                    >
                      <Typography noWrap>{item.label}</Typography>
                    </MenuItem>
                  ) : (
                    <Link
                      to={{ pathname: item.link, hash: location.hash, state: { backUrl: item.id === 'exhibition' ? location.pathname : '' } }}
                      className={classes.link}
                      onClick={() => {
                        if (isActive && toggleMenu) {
                          toggleMenu();
                        }
                      }}
                    >
                      <MenuItem
                        classes={{ root: clsx({
                            [classes.menuItem]: true,
                            [classes.activeMenuItem]: isActive,
                          })}}
                      >
                        <Typography noWrap>{item.label}</Typography>
                      </MenuItem>
                    </Link>
                  )}
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        {false && (
          <Grid item className={classes.locales}>
            {Object.keys(availableLanguages).map((key) => {
              const l = availableLanguages[key];
              const selected = key === locale;
              return (
                <IconButton
                  key={key}
                  disabled={selected}
                  onClick={() => handleChangeLanguage(key)}
                  classes={{ disabled: classes.selected }}
                  data-testid={key}
                >
                  <img src={l.flag} alt={l.name} />
                </IconButton>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default Menu;
