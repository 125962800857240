import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useLocalization } from '../context/localization';
import Layout from '../components/Layout';
import organizers from '../static/organizers.png';

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
  },
}));

function OrganizersView() {
  const classes = useStyles();
  const { translate } = useLocalization();
  return (
    <Layout title={translate('organizers.title')}>
      <img src={organizers}  alt="organizers" className={classes.content} />
    </Layout>
  )
}

export default OrganizersView;
