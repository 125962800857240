const pl = {
  // about
  'about.title': 'O projekcie',
  'about.exhibitionTitle': 'AKCES I ZWROT',
  'about.exhibitionSubtitle': 'Wystawa 6. Konkursu Najlepszych Dyplomów Sztuki Mediów',
  'about.openingTitle': 'Godziny otwarcia Centrum Sztuki WRO',
  'about.openingHours': 'wtorek - piątek, 11:00 -14:00 / 16:00 -18:00',
  'about.openTill': 'Wystawa do 28.03.2021',
  'about.subtitle': 'Wystawa 6. Konkursu Najlepszych Dyplomów Sztuki Mediów',
  'about.paragraph1': 'Zapraszamy do odwiedzenia i obejrzenia wystawy AKCES I ZWROT – finałowej ' +
    'ekspozycji wyjątkowej, szóstej edycji Konkursu Najlepszych Dyplomów Sztuki ' +
    'Mediów, organizowanego wspólnie Akademię Sztuk Pięknych im. Eugeniusza ' +
    'Gepperta we Wrocławiu i Centrum Sztuki WRO. Mamy nadzieję, że w niedługim czasie ' +
    'będziemy mogli zaprosić Państwa do galerii, by wystawę i prezentowane na niej prace móc ' +
    'obejrzeć na żywo. Tymczasem dołożyliśmy wszelkich starań, by w tej multimedialnej formie jak ' +
    'najpełniej oddać ekspozycję i tworzące nią realizacje. ',
  'about.paragraph2': 'Coroczny przegląd najlepszych prac dyplomowych od 2015 roku odbywa się na ośmiu ' +
    'publicznych uczelniach artystycznych w Polsce: w Gdańsku, Katowicach, Krakowie, Łodzi, ' +
    'Poznaniu, Szczecinie, Warszawie i Wrocławiu, w których strukturach funkcjonują jednostki ' +
    'dedykowane sztuce mediów/multimediom/intermediom. Prace do wystawy konkursowej w ' +
    'każdym ośrodku nominują pięcioosobowe komisje o zmiennym składzie. ',
  'about.paragraph3': 'W dotychczasowej, 5-letniej historii konkursu, wśród najlepszych dyplomów znalazły się ' +
    'zarówno instalacje wideo, animacje komputerowe, ingerencje w mediach społecznościowych, ' +
    'projekty interaktywne i generatywne, koncerty audiowizualne, realizacje z pogranicza bioartu i ' +
    'sieci neuronowych, jak i działania podejmowane z estetyką cosplay i konwencjami świata gier ' +
    'komputerowych. Tym razem spektrum gatunkowe zostaje poszerzone o realizacje VR, 360° czy ' +
    'wykorzystujące AI. Różnorodność taka podkreśla rosnące znaczenie narzędzi medialnych w ' +
    'obrębie sztuki współczesnej i ich roli w kształtowaniu refleksji nad współczesnością. ',
  'about.paragraph4': 'Zapraszamy na wystawę!',
  'about.seeOpening': 'Obejrzyj transmisję z otwarcia',
  // artists
  'artists.title': 'Artyści i prace',
  'artists.see': 'Zobacz',
  'artists.mbolt.artwork': 'Water Memory',
  'artists.mbolt.bio': 'Małgorzata Bolt (1992) - w swoich artystycznych wypowiedziach korzysta z różnych środków. ' +
    'Tworzy obiekty, instalacje, wideo. Pracuje również z dźwiękiem. Do poruszanych przez nią problemów w pracach, ' +
    'należą zagrożenia wypływające z nieodpowiedzialnej działalności człowieka. W swoich realizacjach stara się używać ' +
    'materiałów pochodzących z recyklingu. Brała udział w kilku wystawach zbiorowych m.in. w Centrum Sztuki Współczesnej ' +
    'Łaźnia, Gdańskiej Galerii Miejskiej oraz w Zbrojowni Sztuki ASP w Gdańsku.',
  'artists.mbolt.description': 'Bezpośrednim impulsem do stworzenia pracy „Water Memory” stała się sytuacja niemieckiego ' +
    'tankowca T/S Franken, zatopionego w 1945 roku przez radzieckie lotnictwo, zalegającego na dnie Zatoki Gdańskiej. ' +
    'Z raportu Instytutu Morskiego w Gdańsku oraz Fundacji MARE wynika, że na skutek korozji statek może w niedługim ' +
    'czasie ulec rozpadowi. W jego zbiornikach wciąż znajduje się od 1,5 do 5 milionów litrów paliwa.  Jego wydostanie ' +
    'się do morza doprowadziłoby do skażenia całej Zatoki Gdańskiej.\nOszczędna w środkach praca Małgorzaty Bolt, ' +
    'przypominać ma o ludzkich błędach, zaniedbaniach, nieodpowiedzialności, o deficycie myślenia na temat środowiska ' +
    'naturalnego, a także o katastrofie ekologicznej zagrażającej całemu rejonowi Trójmiasta i Helu. Gładka tafla ' +
    'rozlanego oleju działa jak lustro, w którym nasze odbicie rozmywa się raz na 25 sekund. Drgania wywołują ' +
    'niepokojący dźwięk. Na trzech pozostałych częściach instalacji rozwieszona została gruba folia, która napręża się ' +
    'pod ciężarem wody.',
  'artists.mbolt.fromShort': 'Akademia Sztuk Pięknych w Gdańsku',
  'artists.mbolt.fromLong': 'Akademia Sztuk Pięknych w Gdańsku\n' +
    'Wydział Rzeźby i Intermediów, Katedra Intermediów\n' +
    'Pracownia Działań Transdyscyplinarnych\n' +
    'Promotor: prof. dr hab. Grzegorz Klaman',
  'artists.mbolt.name': 'Małgorzata Ewa Bolt',
  'artists.mgniady.artwork': 'Słoń',
  'artists.mgniady.bio': 'Maciej Gniady (1988) - artysta intermedialny zajmujący się nurtem sztuki-nauki (art-science), ' +
    'wykorzystuje w swoich działaniach przestrzenie wirtualne, czujniki ruchu i syntezatory dźwięku. W swoich pracach ' +
    'bada takie zjawiska jak: cybernetyczna społeczność, rzeczywistość hybrydowa, transhumanizm czy poezja cybernetyczna. ' +
    'Członek Kolektywu DAS. Swoje prace realizował między innymi w Wooster Group Performing Garage w Nowym Jorku, ' +
    'Teatrze Rozmaitości, Teatrze Nowym, Teatrze Powszechnym w Warszawie i  Teatrze Polskim w Podziemiu we Wrocławiu, ' +
    'CSW Zamek Ujazdowski w Warszawie, Bunkrze Sztuki i Kopcu Kościuszki w ramach festiwalu Genius Loci, ' +
    'ArsTechne oraz Audio Art. Laureat Stypendium Ministra Kultury i Dziedzictwa Narodowego (2016) oraz ' +
    'Diamentowego Grantu (2017). Od 2018 roku doktorant na Akademii Sztuk Pięknych w Krakowie.',
  'artists.mgniady.description': 'W przestrzeni instalacji zamontowany został układ laserów wirtualnej rzeczywistości. ' +
    'Artysta łączy w swojej pracy świat realny z przestrzenią wirtualną, którą możemy poznać jedynie poprzez specjalne ' +
    'urządzenie, przekształcające dźwięk w haptyczne bodźce. Sposób, w jaki będzie generowana cyfrowa projekcja, ' +
    'jest zależny od tego, jak osoba obsługująca specjalny kontroler będzie „dotykać” wirtualnego obiektu znajdującego ' +
    'się na środku instalacji – trójwymiarowego modelu słonia, który jednak  nie jest prezentowany w całości, lecz we ' +
    'fragmentach. W zależności od tego, jakie ruchy wykona użytkownik, zobaczy inną część ciała zwierzęcia. Ze względu ' +
    'na zaciemnienie pokoju musi polegać na takich zmysłach jak słuch oraz dotyk.\nArtysta wykorzystał motywy tradycyjnej ' +
    'hinduskiej przypowieści o „Niewidomych i słoniu”, gdzie grupa niewidomych próbowała za pomocą dotyku zbadać, ' +
    'jak wygląda słoń. Ponieważ każdy z nich dotknął go w inną część ciała, niemożliwe było dokładne opisanie wyglądu ' +
    'zwierzęcia. W kontekście pracy Słoniem jest niemożność dokładnego zbadania rzeczywistości. Nasze ograniczone zmysły ' +
    'oraz aparat poznawczy nie są w stanie zbadać w pełni tego, co nas otacza, co metaforyzuje artysta, przenosząc ' +
    'elementy tradycyjnej przypowieści w świat wirtualny.',
  'artists.mgniady.fromShort': 'Akademia Sztuk Pięknych im. Jana Matejki w Krakowie',
  'artists.mgniady.fromLong': 'Akademia Sztuk Pięknych im. Jana Matejki w Krakowie\n' +
    'Wydział Intermediów\n' +
    'Promotor: dr. hab. Grzegorz Biliński',
  'artists.mgniady.name': 'Maciej Gniady',
  'artists.kgrzywacz.artwork': 'Martwa Natura',
  'artists.kgrzywacz.bio': 'Krystian Grzywacz (1994) - absolwent Akademii Sztuk Pięknych w Gdańsku (2016) i ' +
    'Akademii Sztuk Pięknych we Wrocławiu (2019). Artysta multimedialny. Pracuje z wideo, animacją, VR i rzeźbą, ' +
    'najczęściej realizuje zróżnicowane symulacje. Jego prace pokazywane były przy okazji wystaw zbiorowych zarówno ' +
    'w Polsce i poza granicami. Laureat głównej nagrody 3. Konkursu Najlepszych Dyplomów Sztuki Mediów ' +
    '(2017, licencjacka praca “Pustostany”). Współtworzy trójmiejski kolektyw DJsko-towarzysko-artystyczny Gnojki. ',
  'artists.kgrzywacz.description': '„Martwa Natura” to realizacja, którą artysta określa jako ruchomą pocztówkę ' +
    'z międzyczasu technologicznej ewolucji. Martwość tej natury można rozumieć w dwójnasób - pojawiają się tu opisy ' +
    'i obrazy przemijania, umierania czy upadku. Istnieje również drugi sposób odczytania tytułu – wszystko stworzone ' +
    'jest sztucznie. Film nie jest uchwyceniem za pomocą kamery prawdziwych roślin czy ludzi. Artysta stworzył całość ' +
    'obrazu w programach graficznych. Przedstawiona natura jest nieożywiona, jest martwa nie poprzez swą śmierć czy ' +
    'statyczność, lecz poprzez to, że nigdy nie była żywa. Grzywacz proponuje również „syntetyczną” narrację. ' +
    'Nie on jest autorem poetyckich opisów sytuacji – to treści generowane przez okrojoną wersję programu GPT-2, ' +
    'czyli system Sztucznej Inteligencji, który jest w stanie rozwijać tekst na podstawie fragmentu materiału wyjściowego, ' +
    'naśladując jego ton i tematykę. Opracowała go grupa badawcza OpenAl, na której czele stał Elon Musk. ' +
    'Pełna wersja programu nigdy nie została upubliczniona, udostępniono natomiast jego późniejszą, słabszą wersję ' +
    'o nazwie GPT-2 345M, pochodzącą od 345 000 000 parametrów, które wykorzystuje przy przewidywaniu kolejnych ' +
    'słów generowanego tekstu.',
  'artists.kgrzywacz.fromShort': 'Akademia Sztuk Pięknych im. Eugeniusza Gepperta we Wrocławiu',
  'artists.kgrzywacz.fromLong':'Akademia Sztuk Pięknych im. Eugeniusza Gepperta we Wrocławiu\n' +
    'Wydział Grafiki i Sztuki Mediów\n' +
    'Pracownia Narracji i Interakcji w Nowych Mediach\n' +
    'Promotor: dr hab. Maja Wolińska',
  'artists.kgrzywacz.name': 'Krystian Grzywacz',
  'artists.mmikolajczykabuczynska.artwork': 'Portret',
  'artists.mmikolajczykabuczynska.bio': 'Marta Mikołajczyk (1994) - laureatka II nagrody oraz Nagrody Publiczności ' +
    'w konkursie najlepszych dyplomów licencjackich Prime Stage 2019. Uczestniczyła w warsztatach lalkarskich Sztuka ' +
    'Tworzenia Lalki organizowanych przez WJT Studio, Momakin, Łódź Film Commission oraz EC1 Łódź – Miasto Kultury. ' +
    'Zawodowo związana z branżą scenograficzną. Uczestniczyła w tworzeniu scenografii do filmu animowanego „Zapomniana ' +
    'książka”. Obecnie zajmuje się projektowaniem scenografii w przestrzeni publicznej. Interesuje się modelarstwem, ' +
    'rysunkiem oraz historią sztuki.\nAgnieszka Buczyńska (1995), laureatka II nagrody oraz Nagrody Publiczności w konkursie najlepszych dyplomów ' +
    'licencjackich Prime Stage 2019. Brała udział w warsztatach Sztuka Tworzenia Lalki organizowanych przez WJT Studio, ' +
    'Momakin, Łódź Film Commission oraz EC1 Łódź – Miasto Kultury. Współtworzyła lalki do „Wydarzeń 1970” oraz kostiumy ' +
    'do filmu lalkowego „MOST”. Interesuje się animacją oraz projektowaniem postaci i środowisk do filmów animowanych.',
  'artists.mmikolajczykabuczynska.description': 'Animacja lalkowa zainspirowana opowiadaniem Mikołaja Gogola o tym ' +
    'samym tytule. Autor opowiada historię biednego studenta malarstwa, który pewnego dnia na targowisku kupuje ' +
    'tajemniczy obraz, nie mając pojęcia, że to zdarzenie diametralnie odmieni jego życie. Sceneria osadzona jest w ' +
    'realiach XIX-wiecznej Rosji, a sama historia przepełniona jest nastrojem grozy i tajemniczości. Stworzony przez ' +
    'autorki świat jest przerysowaniem rzeczywistości z elementami baśni i horroru. “Portret” pokazuje, jak bardzo ' +
    'natura ludzka jest podatna na materialne pokusy. Zadaje pytanie o podstawową hierarchię wartości.\nTrwająca 9-minut ' +
    'animacja poklatkowa o częstotliwości 25 klatek na sekundę przedstawia czterech lalkowych bohaterów oraz trzy scenografie.',
  'artists.mmikolajczykabuczynska.fromShort': 'Akademia Sztuk Pięknych im. Władysława Strzemińskiego w Łodzi',
  'artists.mmikolajczykabuczynska.fromLong': 'Akademia Sztuk Pięknych im. Władysława Strzemińskiego w Łodzi\n' +
    'Wydział Sztuk Wizualnych\n' +
    'Katedra Filmu i Mediów Cyfrowych\n' +
    'Pracownia Animacji Lalkowej\n' +
    'Promotor: dr hab. Krzysztof Rynkiewicz',
  'artists.mmikolajczykabuczynska.name': 'Marta Mikołajczyk i Agnieszka Buczyńska',
  'artists.hmuszynski.artwork': 'A ja Ciebie kocham, a ja Ciebie lubię',
  'artists.hmuszynski.bio': 'Horacy Muszyński (1994) - aktualnie uczestnik programu De Ateliers w Amsterdamie. ' +
    'Laureat II nagrody Project Room 2019, Artystycznej Podróży Hestii 2017 oraz II nagrody w festiwalu Młode Wilki 16. ' +
    'Jego filmy pokazywane były m.in. w ramach 68. Cannes Film Festival (2015), Film Polska Berlin (2016), ' +
    'Szczecin European Film Festival (2016), Krakowskiego Festiwalu Filmowego (2017), Przeglądu Sztuki Survival (2019). ' +
    'Muszyński działa na pograniczu kina i sztuki. Do swoich filmów wprowadza elementy performansu – na przykład ' +
    'w filmie “Dolly” aktorzy grający rodzinę musieli pozostać w relacjach rodzinnych także poza planem filmowym. ' +
    'Z kolei w “ID” wynajął aktora, który przez tydzień zastępował Horacego w prawdziwym życiu. Jego drugim źródłem ' +
    'inspiracji są także niszowe filmy drugiej połowy XX w. Dzięki temu powstała “Kishonia” - horror o zmutowanych ' +
    'ogórkach kiszonych,  który w polskiej wersji językowej czyta znany lektor Tomasz Knapik. Zależy mu na tym, ' +
    'aby elitaryzm przestał być cechą środowisk artystycznych, a sztuka była dostępna i zrozumiała dla każdego widza.',
  'artists.hmuszynski.description': '„A ja ciebie kocham, a ja ciebie lubię” to film nakręcony przy pomocy kamery 360 stopni, ' +
    'będący dokumentalnym zapisem ostatnich miesięcy z życia pana Michała Marcinkowskiego – dziadka autora. To film o miłości, ' +
    'czuwaniu i trwaniu. Technologia VR ma określone ograniczenia oraz atuty – film można oglądać w pojedynkę, gdzie widz ' +
    'zostaje wciągnięty w obraz, nierzadko znajdując się w przedziwnych relacjach do przestrzeni. Dzięki technologii ' +
    '360 stopni i goglom VR, ujęcia kręcone z perspektywy kapusty kiszonej, leków, czy paprochów znajdujących się na dywanie, ' +
    'nie tylko są niezwykle intrygujące poznawczo dla widza, ale w przemyślany sposób realizują niejako jedną z refleksji, ' +
    'którą bohater filmu formułuje: jesteśmy miliardową częścią okrucha. Widz zanurzony w obrazie ogląda opowiadaną ' +
    'historię z punktu widzenia wielu różnych aktorów rzeczywistości. Jesteśmy zarówno okruchem, kapustą i lekiem, ' +
    'jak i lekiem, jak i duchem unoszącym się nad ciężko chorym człowiekiem. Ponadto, ponieważ epicentrum narracji ' +
    'znajduje się często poniżej horyzontu spojrzenia, widzowie przyjmują figurę postaci spoglądającej w dół, ' +
    'jakby zasmuconej. Sama historia, poprowadzona jest niechronologicznie. Film zaczyna się od ujęć realizowanych ' +
    'na kilka godzin przed śmiercią bohatera, a ostatnie ujęcia pokazują go przy pracy w kuchni, kilka miesięcy przed.  ' +
    'Zastosowanie takiego rodzaju montażu nie jest bez znaczenia. Jest to zobrazowanie nadziei, która zawsze towarzyszy ' +
    'nam do końca. Za pomocą filmu odwraca się sytuacja. Zamiast śmierci finalnie bohater zostaje uzdrowiony.',
  'artists.hmuszynski.fromShort': 'Akademia Sztuki w Szczecinie',
  'artists.hmuszynski.fromLong': 'Akademia Sztuki w Szczecinie\n' +
    'Wydział Malarstwa i Nowych Mediów\n' +
    'Promotorzy: dr hab. Hubert Czerepok, mgr Teresa Otulak oraz dr hab. Zbigniew Taszycki, dr Agnieszka Grodzińska',
  'artists.hmuszynski.name': 'Horacy Muszyński',
  'artists.kmutke.artwork': 'Obserwatorium dźwięku',
  'artists.kmutke.bio': 'Kacper Mutke (1994) - zajmuje się działaniami w obszarze creative coding, sztuki generatywnej, ' +
    'szeroko pojętym eksperymentem wizualnym, instalacji multimedialnych, animacji, malarstwa, VJ-ingu i dźwięku. ' +
    'Swoje prace prezentował na wystawach i festiwalach w Polsce i za granicą, m.in. w Holandii, Chorwacji, Chinach. ' +
    'Zostały one kilkukrotnie wyróżnione m.in. na cineRAMAfest (2017), Międzynarodowym Festiwalu Rysowania ' +
    '(2018) czy AGRAFA (2019). Uczestnik rezydencji artystycznych w Polsce i Francji. Wraz z Michałem Urbańskim ' +
    'współtworzy kolektyw tajny_projekt działający w obszarze nowych mediów. Od 2020 roku współprowadzi pracownię ' +
    'gościnną na Wydziale Grafiki Szczecińskiej Akademii Sztuki, w której wraz ze studentami tworzy instalacje ' +
    'multimedialne. Stypendysta Ministra Kultury i Dziedzictwa Narodowego w 2018 i 2020 roku.',
  'artists.kmutke.contributors': 'Muzyka: Tomasz Bednarczyk\n' +
    'Lektor: Jarosław Łukomski\n' +
    'Podziękowania: Michał Urbański (tajny_projekt), Piotr Ceglarek, Filip Mutke',
  'artists.kmutke.description': 'Dwuczęściowa interaktywno-edukacyjna instalacja multimedialna służąca do obserwacji dźwięku. ' +
    'Animacyjna część instalacji dostarcza użytkownikowi podstawowej wiedzy na temat fizycznych właściwości fal dźwiękowych. ' +
    'Część interaktywna generuje z kolei dźwięk za pomocą kontrolera - syntezatora - wizualizująć wytwarzany, ' +
    'interpretowany poprzez specjalnie zaadoptowany do tego ekran Vectrex. Instalacja umożliwia dostrzeżenie zmian ' +
    'zachodzących w przebiegu dźwięku po zmianie podstawowych parametrów, takich jak amplituda czy częstotliwość, ' +
    'dodaniu efektów, filtrów audio lub użyciu mikrofonu. Całość doświadczenia przybliża oglądającemu naturę dźwięku ' +
    'oraz pokazuje logikę obrazów - drgań harmonicznych znanych jako figury Lissajous.',
  'artists.kmutke.fromShort': 'Akademia Sztuk Pięknych w Katowicach',
  'artists.kmutke.fromLong': 'Akademia Sztuk Pięknych w Katowicach\n' +
    'Wydział Projektowy\n' +
    'Pracownia Działań Multimedialnych\n' +
    'Promotor: prof. dr hab. Marian Oslislo',
  'artists.kmutke.name': 'Kacper Mutke',
  'artists.swildstein.artwork': 'Klasztor głupiego myślenia',
  'artists.swildstein.bio': 'Szymon Wildstein (1988) - absolwent Print and Time Based Media na University of the Arts ' +
    'London oraz kierunku Intermedia na Uniwersytecie Artystycznym w Poznaniu. Tworzy instalacje, słuchowiska, muzykę, ' +
    'obrazy, rysunki i teksty literackie. Współzałożyciel razem z Dominiką Święcicką niezależnej przestrzeni artystycznej ' +
    'Atelier Artel, nawiązującej zarówno w nazwie, jak i formie do rosyjskich arteli.',
  'artists.swildstein.description': 'Projekt  „Klasztor głupiego myślenia” jest instalacją składającą się ' +
    'z prostego regału, na którego półkach znajdują się zielona książka, dostępna do przeglądania dla odbiorcy ' +
    'oraz wybrane modele pomysłów na prace artystyczne, które zostały w niej zawarte. Książka opisuje jeden dzień ' +
    'z życia studenta Uniwersytetu Artystycznego w Poznaniu, łączącego naukę i działalność artystyczną z pracą ' +
    'montażysty programu newsowego w lokalnej telewizji. Wykonując swoje obowiązki, rozmyśla nad kolejnymi projektami. ' +
    'Narrator wieńczy opis swojego dnia konstatacją na temat sensowności studiowania na artystycznej uczelni, ' +
    'jak i w ogóle praktykowania sztuki. Praca zawiera dodatkową wiedzę na temat funkcjonowania telewizji, którą odbiorca ' +
    'poznaje dzięki opisom narratora, a także liczne historie z tzw. światka artystycznego. Choć wydają się to światy ' +
    'od siebie odległe, to jednak w głowie narratora zaczynają się przenikać. Zawarte w książce opisy wymyślonych ' +
    'projektów artystycznych odnoszą się na płaszczyźnie formalnej do „Locus Solus” autorstwa Raymonda Roussela.',
  'artists.swildstein.fromShort': 'Uniwersytet Artystyczny w Poznaniu',
  'artists.swildstein.fromLong': 'Uniwersytet Artystyczny w Poznaniu\n' +
    'Wydział Animacji i Intermediów\n' +
    'Katedra Intermediów\n' +
    'Pracownia Filmu Eksperymentalnego\n' +
    'Promotor: dr hab. Piotr Bosacki prof. UAP',
  'artists.swildstein.name': 'Szymon Wildstein',
  'artists.awitczak.artwork': 'Pozostałość',
  'artists.awitczak.bio': 'Agata Witczak (1995) - absolwentka Wydziału Dziennikarstwa Uniwersytetu Warszawskiego (2014-2017) ' +
    'oraz Wydziału Sztuki Mediów warszawskiej Akademii Sztuk Pięknych (2017-2019). Laureatka nagrody głównej w kategorii ' +
    'debiuty na festiwalu VMA Poland za teledysk do utworu Juliana Uhu „Aha” (2018), za ten sam teledysk nominowana' +
    ' w kategorii plastyczna aranżacja przestrzeni na Festiwalu Polskich Wideoklipów YACH FILM (2019). Członkini ' +
    'kolektywu kreatywnego MUS Warsaw. Od 2019 roku asystentka w Pracowni Relacji Wizualnych prof. Prota Jarnuszkiewicza. ' +
    'Tworzy prace fotograficzne i wideo, zawodowo zajmuje się scenografią.',
  'artists.awitczak.description': 'Projekt „Pozostałość” składa się z trzech realizacji wideo ukazujących wpływ człowieka' +
    ' na otaczającą go materię oraz późniejsze rozproszenie skutków jego działania. Każde z ujęć przedstawia sytuację ' +
    'zaraz po interakcji między człowiekiem a otoczeniem. Praca ma na celu ich wizualne opisanie. Odnosi się do ' +
    'różnych działań: biernych – nawiązujących do nieustannej siły nacisku, wywieranej na stykające się z ciałem powierzchnie, ' +
    'mimowolnych – takich jak pozostawianie po sobie ciepła oraz działania człowieka, którego skutkiem jest wywarcie ' +
    'widocznego wpływu na formę otoczenia.\nZamysłem pracy jest wizualne przedstawienie relacji człowiek – przedmiot – świat. ' +
    'Przedmioty posiadają właściwości pozwalające na zachowanie i zapamiętanie naszej energii. W każdym z przedstawionych ' +
    'przypadków ślad po ludzkim działaniu zanika, a energia przekazana przedmiotom zostaje rozproszona. Jej zanik ' +
    'spowodowany jest przez nieustannie działającą siłę entropii. Chwilowość i przemijanie pozostałych po interakcji ' +
    'śladów jest kluczowym przekazem pracy.',
  'artists.awitczak.fromShort': 'Akademia Sztuk Pięknych w Warszawie',
  'artists.awitczak.fromLong': 'Akademia Sztuk Pięknych w Warszawie\n' +
    'Wydział Sztuki Mediów\n' +
    'Pracownia Relacji Wizualnych\n' +
    'Promotor: prof. dr hab. Prot Jarnuszkiewicz',
  'artists.awitczak.name': 'Agata Witczak',
  // exhibition
  'exhibition.start': 'Rozpocznij zwiedzanie',
  'exhibition.see': 'Zobacz',
  // main
  'conference.title': 'Konferencja',
  'conference.content': 'W związku z panującym stanem pandemii  coroczna konferencja towarzysząca Konkursowi' +
    'Najlepszych Dyplomów Sztuki Mediów odbyła się w tym roku 20 listopada, w wersji online.\n' +
    'Biorąc pod uwagę sytuację w jakiej wszyscy się znaleźliśmy, skupiliśmy się tym razem na zagadnieniach' +
    'bezpośrednio związanych z dydaktyką i praktyką artystyczną w zakresie multimediów, realizowaną w formie zdalnej. ' +
    'Stąd też hasło wywoławcze tegorocznych obrad brzmi: „Z dystansem”. W tym lapidarnym zwrocie w naturalny sposób ' +
    'ujawnia się odniesienie do dystansu fizycznego, ale hasło to może też przywodzić na myśl zachowanie rozsądnego ' +
    'dystansu do panującej sytuacji, czy też spojrzenie z dystansem na dziedzinę, którą się wszyscy zajmujemy.\n' +
    'Nie chodzi nam rzecz jasna o przedstawianie skrupulatnych raportów z pracy zdalnej, lecz o opisanie zaskakujących, ' +
    'niestandardowych sytuacji (pomijając ogólną wyjątkowość aktualnego stanu rzeczy), podzielenie się dobrymi praktykami, ' +
    'które zostały wypracowane w ostatnim czasie na Państwa uczelniach, czy też omówienie zmian w treściach i metodach ' +
    'kształcenia, które zostały wymuszone bądź zainspirowane zaistniałą sytuacją i które planujecie Państwo wprowadzić ' +
    'już na stałe w post-pandemicznej przyszłości.',
  'conference.program.title': 'Program:',
  'conference.program.session1': 'Sesja nr 1',
  'conference.program.session1.content': '10:00 - Otwarcie konferencji\n' +
    'prof. ucz. Marek Grzyb – dziekan Wydziału Grafiki i Sztuki Mediów\n' +
    'prof. ucz. Jakub Jernajczyk – dziekan Szkoły Doktorskiej (ASP Wrocław)\n\n' +
    '10:15 - Piotr Krajewski (Centrum Sztuki WRO) \n' +
    'Ponowne spojrzenie na doświadczenie edukacyjne\n\n' +
    '10:35 - prof. Joanna Krysa (Liverpool John Moores University)\n' +
    'Od Dyplomu na Marsie do powrotu na Ziemię – raport z Liverpool School of Art and Design\n\n' +
    '11:05 - dr Bartłomiej Skowron (Politechnika Warszawska)\n' +
    'O skracaniu dystansu. Filozoficzny komentarz do srogiego determinizmu technologicznego w dobie pandemii\n\n' +
    '11:35 - Dyskusja',
  'conference.program.session2': 'Sesja nr 2',
  'conference.program.session2.content': '12:20 - mgr Elżbieta Wysakowska-Walters (ASP Kraków)\n' +
    '5 poziomów komunikacji z dystansem\n\n' +
    '12:40 - dr hab. Łukasz Ogórek (ASP Łódź)\n' +
    'Entele.pentele. Film eksperymentalny jako punkt wyjścia do reakcji na epidemiczne restrykcje\n\n' +
    '13:00 - dr Dariusz Fodczuk (AS Szczecin)\n' +
    'Anomalie, błędy, pomyłki\n\n' +
    '13:20 - prof. ucz. Paweł Mendrek (ASP Katowice)\n' +
    'Alternatywna szkoła artystyczna: Dekolonizacja sztuki, badania terenowe i kryzys mobilności\n\n' +
    '13:40 - Dyskusja\n\n' +
    '15:00 - Zakończenie konferencji \n' +
    'dr Paweł Lisek – kierownik Katedry Sztuki Mediów (ASP Wrocław)',
  'conference.linkLabel': 'Całość konferencji do obejrzenia / odsłuchania',
  // map
  'map.title': 'Mapa',
  // organizers
  'organizers.title': 'Organizatorzy',
  // prizes
  'prizes.title': 'Nagrody',
  'prizes.intro': 'Nagrodę główną w wysokości 15.000 zł, ufundowaną przez Ministra Kultury i Dziedzictwa Narodowego,' +
    ' przyznaje w tym roku jury w składzie:',
  'prizes.jurors': 'Agnieszka Jelewska (profesor UAM, dyrektorka HAT - Humanities/Art/Technology Research Center)\n' +
    'Łukasz Gorczyca (Galeria Raster)\n' +
    'Benjamin Gruner (Pochen Biennale)',
  'prizes.content': 'W tym wyjątkowym roku, wyjątkowo nie zostanie przyznana jedna Nagroda Publiczności. Ponieważ ' +
    'trudno przewidzieć, czy odwiedzający będą mieli okazję oddać swe głosy na wybraną pracę, przeznaczona na nagrodę kwota,' +
    'fundowana przez Rektorów uczelni biorących udział w konkursie, zostanie podzielona między wszystkich uczestników.\n' +
    'Po raz pierwszy z kolei redakcja magazynu „Contemporary Lynx” nagrodzi wybranego przez siebie ' +
    'artystę publikacją jego portfolio oraz dodatkowymi działaniami promocyjnymi.\n',
  'prizes.mainPrizeTitle': 'NAGRODA GŁÓWNA',
  'prizes.mainPrizeContent': 'Jury jednogłośnie zdecydowało o przyznaniu nagrody Horacemu Muszyńskiemu za pracę „And I Love You so Much,' +
    ' and I Like You so Much”. Film zrealizowany kamerą 360 stopni łączy głęboko intymną perspektywę z nowymi ' +
    'możliwościami technologicznymi i w nowy sposób przekazuje fundamentalne doświadczenie egzystencjalne jakim jest ' +
    'proces odchodzenia bliskiej osoby. Jury zdecydowało o nagrodzeniu pracy Muszyńskiego doceniając jej klarowną i sugestywną formę, ' +
    'a przede wszystkim przesłanie – niezbywalność humanistycznego wymiaru sztuki nowych mediów.',
  'prizes.contemporaryLynxPrizeTitle': 'NAGRODA MAGAZYNU „CONTEMPORARY LYNX”',
  'prizes.contemporaryLynxPrizeContent': 'Contemporary Lynx, po obejrzeniu tegorocznej wystawy Najlepszych Dyplomów Sztuki Mediów postanowiło przyznać swoją ' +
    'nagrodę Krystianowi Grzywaczowi. Doceniamy konsekwentne rozwijanie tematów, badanie kondycji ludzkiej przy użyciu ' +
    'nowych technologii. Systemy sztucznej inteligencji nie są bohaterami seriali sci-fi lecz zaczynają być narratorami ' +
    'dnia codziennego. Tak jak filozoficzno-egzystencjalna historia prowadząca nas przez film Krystiana okazuje się w ' +
    'całości „sztucznym” tworem wygenerowanym przez program GPT-2, tak i nasza rzeczywistość jest już w ogromnym stopniu ' +
    'kierowana-symulowana przez algorytmy. To nie jest odległa przyszłość, to jest nasza codzienna komunikacja, praca, ' +
    'decyzje i relacje międzyludzkie – szczególnie w tym roku stało się to widoczne. Żyjemy w sieci. Jesteśmy prymitywną ' +
    'formą awatarów (prymitywną, gdyż korzystającą z narzędzi powierzchownie, nie mając świadomości całości ich możliwości, ' +
    'znaczenia, wpływu i naszego w nich uwikłania). Jeszcze krok i zatracimy swoją cielesność i niczym syntetycznie wygenerowane ' +
    'modele Grzywacza będziemy zmierzali ku samounicestwieniu potykając się o cyfrowe przeszkody. Natura, którą konsekwentnie ' +
    'ludzkość niszczy zostaje zastąpiona przez tytułową „martwą naturę” – to kierunek, w którym niechlubnie dążymy.',
  // curatorialText
  'curatorialText.title': 'Tekst kuratorski',
  'curatorialText.subtitle': 'Otwieramy wystawę!',
  'curatorialText.paragraph1': 'Wystawę już znaną, bo choć udostępnianą publiczności mogącej wejść do galerii dopiero teraz, ' +
    'to na różne sposoby prezentowaną już online, zatem poznawaną przez kilka tygodni znajomością zapośredniczoną.',
  'curatorialText.paragraph2': 'Dane są: wydziały, kierunki lub specjalności polskich uczelni artystycznych kształcących ' +
    'w dziedzinie nowych mediów. Od kilkunastu do kilkudziesięciu dyplomów licencjackich i magisterskich bronionych każdego roku. ' +
    'Konkurs. Częstotliwość: co roku. Niezależność wyborów: zachowana. Obiektywność kryteriów: wysoka. Obecny konkurs dyplomów ' +
    'sztuki mediów organizowany jest po raz szósty, jednak po raz pierwszy w warunkach ograniczeń jakie na instytucje kultury ' +
    'nałożył okres pandemii.',
  'curatorialText.paragraph3': 'Wszystkie wybrane prace powstały jeszcze przed okresem pandemii, niejako bez przeczucia ' +
    'ograniczeń funkcjonowania świata sztuki. Przed koniecznością kalkulowania alternatywnych w stosunku do galerii sposobów ' +
    'kontaktu z publicznością. We wszystkich pracach ważny jest jeszcze aspekt bezpośredniego przeżycia, niemal niedostępny na odległość, ' +
    'ginący w zapośredniczeniach.',
  'curatorialText.paragraph4': 'Materiały, jakie do tej pory zostały opublikowane nie są więc alternatywą dla wystawy, ' +
    'lecz przede wszystkim sposobem krążenia wokół niej, prowadzącym w finale do wizyty w galerii. Dzięki cyfrowej replice ' +
    'wnętrza galerii, udostępnieniu dokumentacji dzieł i wypowiedzi autorów, galerii zdjęć, fanzinowi i innym materiałom ' +
    'publikowanym na stronie wystawy oraz transmisjom i pozostałym aktywnościom w mediach społecznościowych, staraliśmy się dawać, ' +
    'zamiast poczucia zwiedzenia wystawy – inspirację do zobaczenia jej w realnej przestrzeni.',
  'curatorialText.paragraph5part1': 'Wystawy dyplomów sztuki mediów początkowo nie miały odrębnego tytułu. Dopiero tę odbywającą ' +
    'się w ramach',
  'curatorialText.paragraph5link': 'Biennale WRO 2019 CZYNNIK LUDZKI / HUMAN ASPECT',
  'curatorialText.paragraph5part2': 'zorganizowaliśmy pod hasłem AKCES, podkreślając fakt, ' +
    'iż dyplom jest zgłoszeniem kierowanym w stronę świata sztuki. Po okresie edukacji, niedawni dyplomanci stają już wobec ' +
    'niego samodzielni. To sytuacja w pewnym sensie uniwersalna, powtarzająca się z każdym dyplomem, dlatego postanowiliśmy ' +
    'tę nazwę utrzymać także dla wystaw kolejnych konkursów, ukazujących młodych artystów w poszukiwaniu miejsca dla swojej twórczości. ' +
    'Oddalanie się od edukacji, weryfikowanie założeń, dokonywanie przewartościowań, w poszukiwaniu miejsca dla siebie, ' +
    'choć jest sytuacją uniwersalną, to jednak każda z autorek i każdy z autorów musi tego dokonać na własny sposób. ' +
    'Dokonać samodzielnego zwrotu, tak aby przestać być „absolwentem”, stając się po prostu twórcą. Tak więc teraz obok „akcesu” ' +
    'w tytule obecnej wystawy pojawia się również „zwrot”.',
  'curatorialText.paragraph6': 'Zwrot ten dotyczy zresztą także samej wystawy, której losy okazały się obfitować w niespodziewane zwroty. ' +
    'Planowana na wiosnę 2020, z uwagi na lockdown i ograniczenia działalności zarówno szkół artystycznych jak galerii, ' +
    'cześć postępowania konkursowego na uczelniach została przesunięta i przeniesiona w sferę online. Ta sfera online, ' +
    'aż do teraz, stawała się też coraz bardziej formą prezentacji wystawy, aż po kolejny zwrot – jej fizyczne otwarcie, ' +
    'odbywające się teraz. Na kolejnym poziomie tytuł komentuje także uderzającą odmienność wobec rezultatów poprzedniego konkursu, ' +
    'który pokazał pewną kontestację, ironię, czy wręcz nieufność młodych artystek i artystów wyrażaną wobec obszaru nowych mediów, ' +
    'z którym to nie do końca chcieli być utożsamiani.',
  'curatorialText.paragraph7': 'Prezentowane dzieła powstały raczej w przymierzu z technologią. Autorki i autorzy w różnorodny sposób ' +
    'wykorzystali szeroki współcześnie ale i historycznie obszar. Na wystawie pojawiają się więc prace odnoszące się do „nowomedialnej” ' +
    'tradycji, poszukujące strukturalnych interakcji między dźwiękiem a obrazem, przestrzenią a tekstem, konstrukcją a intermedialnością ' +
    'formy otwartej, posługujące się kontemplatywnością wieloekranowości i subtelnościami animacji. Obok nich obecne są dzieła realizowane ' +
    'z użyciem nowszego obrazowania VR, stworzone językiem aplikacji, programowania doświadczeń ze sztuczną inteligencją.',
  'curatorialText.paragraph8': 'Wystawa przestrzeni Centrum Sztuki WRO, zaaranżowana została w sposób pozwalający przede wszystkim ' +
    'na samodzielne wybrzmienie każdego z prezentowanych dzieł, ale też tworzy dla nich wspólne wizualne środowisko, oraz przestrzeń ' +
    'realnych dynamicznych oddziaływań.',
};

export default pl;
