import React from 'react';
import {Typography, makeStyles, Grid} from '@material-ui/core';
import { useLocalization } from '../context/localization';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  paragraph: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  jurors: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  juror: {
    fontSize: 14,
    marginBottom: theme.spacing(1),
  },
}));

function PrizesView() {
  const classes = useStyles();
  const { translate } = useLocalization();
  return (
    <Layout title={translate('prizes.title')}>
      <Typography color="primary" className={classes.paragraph}>{translate('prizes.intro')}</Typography>
      <Grid item className={classes.jurors}>
        {translate('prizes.jurors').split('\n').map(text => (
          <Typography key={text} component="li" color="primary" className={classes.juror}>{text}</Typography>
        ))}
      </Grid>
      {translate('prizes.content').split('\n').map(text => (
        <Typography key={text} color="primary" className={classes.paragraph}>{text}</Typography>
      ))}
      <Typography color="secondary" className={classes.paragraph}>{translate('prizes.mainPrizeTitle')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('prizes.mainPrizeContent')}</Typography>
      <Typography color="secondary" className={classes.paragraph}>{translate('prizes.contemporaryLynxPrizeTitle')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('prizes.contemporaryLynxPrizeContent')}</Typography>
    </Layout>
  )
}

export default PrizesView;
