import React  from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import { useLocalization } from '../context/localization';
import Layout from '../components/Layout';
import artists from '../context/artists';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
  artistSubtitle: {
    marginBottom: theme.spacing(2),
  }
}));

function ArtistsView() {
  const classes = useStyles();
  const location = useLocation();
  const { translate } = useLocalization();
  return (
    <Layout title={translate('artists.title')}>
      {artists.map(artist => (
        <Link
          key={artist.id}
          to={{ pathname: `/artists/${artist.id}`, hash: location.hash }}
          className={classes.link}
        >
          <Typography color="primary">{`${translate(artist.name)} „${translate(artist.artwork)}”`}</Typography>
          <Typography color="primary" variant="body2" className={classes.artistSubtitle}>{translate(artist.fromShort)}</Typography>
        </Link>
      ))}
    </Layout>
  )
}

export default ArtistsView;
