import { useState, useCallback } from 'react';
import constate from 'constate';

function usePresentationOpen() {
  const [presentationOpen, setOpen] = useState(false);
  const setPresentationOpen = useCallback((value) => {
    setOpen(value);
    // if (!value && window.player?.reset) {
    //   window.player.reset();
    // }
  }, [])
  return { presentationOpen, setPresentationOpen };
}

const [PresentationOpenProvider, usePresentationOpenContext] = constate(usePresentationOpen);

export { PresentationOpenProvider, usePresentationOpenContext };
