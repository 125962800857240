import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {Typography, makeStyles, Button, Grid} from '@material-ui/core';
import { useLocalization } from '../context/localization';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  infoWrapper: {
    marginBottom: theme.spacing(2),
  },
  info: {
    fontSize: 14,
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
  },
  seeOpeningLink: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  linkLabel: {
    fontSize: 14,
  },
  actionButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    fontWeight: 'bold',
  },
}));

function AboutView() {
  const classes = useStyles();
  const { translate } = useLocalization();
  const location = useLocation();
  const handleOpenExhibition = useCallback(() => {
    window.player.reset();
  }, []);
  return (
    <Layout title={translate('about.title')}>
      <Grid item className={classes.infoWrapper}>
        <Typography color="primary" className={classes.info}>{translate('about.exhibitionTitle')}</Typography>
        <Typography color="primary" className={clsx(classes.info, classes.subtitle)}>{translate('about.exhibitionSubtitle')}</Typography>
        <Typography color="primary" className={classes.info}>{translate('about.openingTitle')}</Typography>
        <Typography color="primary" className={classes.info}>{translate('about.openingHours')}</Typography>
        <Grid item className={classes.seeOpeningLink}>
          <a href='https://www.facebook.com/722388804461371/videos/1943110985828062' className={classes.link} target="_blank" rel="noreferrer">
            <Typography component="span" color="secondary" className={classes.linkLabel}>{translate('about.seeOpening')}</Typography>
          </a>
        </Grid>
        <Typography color="primary" className={classes.info}>{translate('about.openTill')}</Typography>
      </Grid>
      <Link
        key="start-exhibition-button"
        to={{pathname: '/exhibition', hash: location.hash, state: {backUrl: location.pathname}}}
        className={classes.link}
      >
        <Button
          color="secondary"
          onClick={handleOpenExhibition}
          className={classes.actionButton}
        >
          {translate('exhibition.start')}
        </Button>
      </Link>
      <Typography color="primary" className={classes.paragraph}>{translate('about.paragraph1')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('about.paragraph2')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('about.paragraph3')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('about.paragraph4')}</Typography>
    </Layout>
  )
}

export default AboutView;
