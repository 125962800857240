import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useLocalization } from '../context/localization';
import Layout from '../components/Layout';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  paragraph: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  program: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  eventWrapper: {
    marginBottom: theme.spacing(3),
  },
  event: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  eventTitle: {
    fontSize: 14,
  },
  eventContent: {
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
  linkLabel: {
    fontSize: 14,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}));

function ConferenceView() {
  const classes = useStyles();
  const { translate } = useLocalization();
  return (
    <Layout title={translate('conference.title')}>
      <Grid item>
        {translate('conference.content').split('\n').map(text => (
          <Typography color="primary" className={classes.paragraph} key={text}>{text}</Typography>
        ))}
      </Grid>
      <Grid item>
        <a href='https://www.youtube.com/watch?v=ZgbCecOFp78' className={classes.link} target="_blank" rel="noreferrer">
          <Typography component="span" color="secondary" className={classes.linkLabel}>{translate('conference.linkLabel')}</Typography>
        </a>
      </Grid>
      <Grid item className={classes.program}>
        <Typography color="primary" className={classes.paragraph}>{translate('conference.program.title')}</Typography>
        <Typography color="primary" className={classes.paragraph}>{translate('conference.program.session1')}</Typography>
        <Grid item className={classes.eventWrapper}>
          {translate('conference.program.session1.content').split('\n\n').map(event => (
            <Grid item key={event} className={classes.event}>
              {event.split('\n').map((text, index) => (
                <Typography color="primary" className={clsx({[classes.eventTitle]: true, [classes.eventContent]: index > 0})} key={text}>{text}</Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Typography color="primary" className={classes.paragraph}>{translate('conference.program.session2')}</Typography>
        <Grid item className={classes.eventWrapper}>
          {translate('conference.program.session2.content').split('\n\n').map(event => (
            <Grid item key={event} className={classes.event}>
              {event.split('\n').map((text, index) => (
                <Typography color="primary" className={clsx({[classes.eventTitle]: true, [classes.eventContent]: index > 0})} key={text}>{text}</Typography>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ConferenceView;
