import { useQuery } from 'react-query';
import axios from 'axios';

export function getPresentation({ id, ...options }) {
  return axios({
    url: `https://evryplace.com/api/v1/presentations/${id}`,
    method: 'get',
    ...options,
  });
}

export function usePresentation({ id, ...options } = {}) {
  return useQuery(
    ['presentationData', id],
    async () => {
      const { data } = await getPresentation({ id, ...options });
      return data;
    },
    options,
  );
}
