import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useLocalization } from '../context/localization';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  paragraph: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  linkLabel: {
    fontSize: 14,
  },
}));

function CuratorialTextView() {
  const classes = useStyles();
  const { translate } = useLocalization();
  return (
    <Layout title={translate('curatorialText.title')}>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.subtitle')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.paragraph1')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.paragraph2')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.paragraph3')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.paragraph4')}</Typography>
      <Typography color="primary" className={classes.paragraph}>
        {translate('curatorialText.paragraph5part1')}
        <a href='http://wro2019.wrocenter.pl/bwa-studio/' className={classes.link} target="_blank" rel="noreferrer">
          <Typography component="span" color="secondary" className={classes.linkLabel}>{translate('curatorialText.paragraph5link')}</Typography>
        </a>
        {translate('curatorialText.paragraph5part2')}
      </Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.paragraph6')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.paragraph7')}</Typography>
      <Typography color="primary" className={classes.paragraph}>{translate('curatorialText.paragraph8')}</Typography>
    </Layout>
  )
}

export default CuratorialTextView;
