import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider, Fade, createMuiTheme } from '@material-ui/core';
import { PresentationOpenProvider, usePresentationOpenContext } from './context/presentationOpen';
import { LocalizationProvider } from './context/localization';
import main from './theme/main';
import ArtistsView from './pages/ArtistsView';
import ArtistView from './pages/ArtistView';
import MapView from './pages/MapView';
import AboutView from './pages/AboutView';
import Exhibition from './pages/Exhibition';
import MenuButton from './components/MenuButton';
import PrizesView from './pages/PrizesView';
import ConferenceView from './pages/ConferenceView';
import OrganizersView from './pages/OrganizersView';
import CuratorialText from './pages/CuratorialText';

function NavigationSwitch() {
  return (
    <Switch>
      <Route path="/artists" exact component={ArtistsView} />
      <Route path="/artists/:id" exact component={ArtistView} />
      <Route path="/curatorialText" exact component={CuratorialText} />
      <Route path="/prizes" exact component={PrizesView} />
      <Route path="/map" exact component={MapView} />
      <Route path="/conference" exact component={ConferenceView} />
      <Route path="/organizers" exact component={OrganizersView} />
      <Route path="/exhibition" component={Exhibition} />
      <Route path="/" component={AboutView} />
    </Switch>
  );
}

function Content() {
  const { presentationOpen } = usePresentationOpenContext();
  const theme = createMuiTheme(main);

  return (
    <React.Fragment>
      <div id="evr-player" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: -1 }} />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {presentationOpen && (<MenuButton />)}
        <Fade in={!presentationOpen} mountOnEnter>
          <div>
            <NavigationSwitch />
          </div>
        </Fade>
      </ThemeProvider>
    </React.Fragment>
  );
}

function App() {
  return (
    <LocalizationProvider>
      <PresentationOpenProvider>
        <Router>
          <Content />
        </Router>
      </PresentationOpenProvider>
    </LocalizationProvider>
  );
}

export default App;
