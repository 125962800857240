import React, { useMemo, useCallback } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Grid, Button, Typography, makeStyles } from '@material-ui/core';
import { useLocalization } from '../context/localization';
import artists from '../context/artists';
import Layout from '../components/Layout';
import { usePresentation } from '../hooks/presentation';

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  sectionItem: {
    fontSize: 14,
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
  actionButton: {
    backgroundColor: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    fontWeight: 'bold',
  },
}));

function ArtistsView() {
  const classes = useStyles();
  const { params: { id: artistId } } = useRouteMatch();
  const artist = useMemo(() => artists.find(art => art.id === artistId), [artistId]);
  const location = useLocation();
  const { translate } = useLocalization();
  const { data: presentationData = [] } = usePresentation({ id: 'bysuol' });
  const scenes = useMemo(() => presentationData?.publicContent?.content?.scenes || [], [presentationData]);
  const artistScene = useMemo(() => scenes.find(scene => scene.name === artist.sceneName), [artist.sceneName, scenes]);
  const handleChangeScene = useCallback((id) => {
    window.player?.setConfig({ rotateScene: false });
    window.player?.changeScene({ sceneId: id });
  }, []);
  return (
    <Layout title={translate(artist.artwork)}>
      <Grid container direction="column">
        <Typography color="secondary" variant="h5" className={classes.subtitle}>{translate(artist.name)}</Typography>
        <Grid item className={classes.section}>
          {artist.fromLong && translate(artist.fromLong).split('\n').map(text => (
            <Typography color="primary" className={classes.sectionItem} key={text}>{text}</Typography>
          ))}
        </Grid>
        {artistScene ? (
          <Grid item className={classes.section}>
            <Link
              key={artistScene.id}
              to={{pathname: '/exhibition', hash: `#${artistScene.id}`, state: {backUrl: location.pathname}}}
              className={classes.link}
            >
              <Button
                color="secondary"
                onClick={() => handleChangeScene(artistScene.id)}
                className={classes.actionButton}
              >
                {translate('exhibition.see')}
              </Button>
            </Link>
          </Grid>
        ) : null}
        <Grid>
          {translate(artist.description).split('\n').map(text => (
            <Typography color="primary" className={classes.paragraph} key={text}>{text}</Typography>
          ))}
        </Grid>
        <Grid item className={classes.section}>
          {artist.contributors && translate(artist.contributors).split('\n').map(text => (
            <Typography color="primary" className={classes.sectionItem} key={text}>{text}</Typography>
          ))}
        </Grid>
        <Grid item>
          {translate(artist.bio).split('\n').map(text => (
            <Typography color="primary" className={classes.paragraph} key={text}>{text}</Typography>
          ))}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ArtistsView;
