import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import constate from 'constate';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { locales, getLocale as getMessages, getTranslationAgent } from '../services/localization';

function onError(err) { // ignore missing translation error
  if (err.code !== 'MISSING_TRANSLATION') {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const cache = createIntlCache();
const defaultIntl = createIntl({
  locale: 'pl-PL',
  defaultLocale: 'pl-PL',
  messages: locales['pl-PL'],
  onError,
}, cache);

function getStoredLocale() {
  return window.localStorage && localStorage.language;
}

function setStoredLocale(language) {
  if (window.localStorage) {
    localStorage.language = language;
  }
}

function useLocalizationHook() {
  const agent = useRef({});
  const [intl, setIntl] = useState(defaultIntl);
  const [locale, setLocaleValue] = useState('pl-PL');
  const storedLocale = getStoredLocale();

  const translate = useCallback(getTranslationAgent(intl), [intl]);
  const changeLocale = useCallback(language => getMessages(language)
    .then(messages => setIntl(createIntl({ locale: language, messages, onError }, cache)))
    .then(() => {
      setStoredLocale(language);
      setLocaleValue(language);
    }), []);
  const getLocale = useCallback(() => locale, [locale]);

  useEffect(() => {
    if (storedLocale && locale !== storedLocale) {
      changeLocale(storedLocale);
    }
  }, [locale, storedLocale, changeLocale]);

  useEffect(() => {
    agent.current.intl = intl;
    agent.current.translate = translate;
  }, [agent, intl, translate]);

  return {
    agent: agent.current,
    intl,
    locale,
    getLocale,
    changeLocale,
    translate,
  };
}

const [IntlProvider, useLocalization] = constate(useLocalizationHook);

function IntlProxy({ children }) {
  const { intl } = useLocalization();
  return (
    <RawIntlProvider value={intl}>
      {children}
    </RawIntlProvider>
  );
}
IntlProxy.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function LocalizationProvider({ children }) {
  return (
    <IntlProvider>
      <IntlProxy>
        {children}
      </IntlProxy>
    </IntlProvider>
  );
}
LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { LocalizationProvider, useLocalization };
